import React, { Component } from 'react'
import Titles from './Common/Titles'
import '../styles/scss/join.scss'
import JoinSuccess from './JoinSuccess'
import phones from '../../data/phones.json'
import ReactCountryFlag from "react-country-flag"
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'

export default class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'contact-us',
                title: {
                    text: 'Contact',
                },
                subtitle: {
                    text: 'us',
                },
                form: {
                    elements: [
                        {
                            type: 'text',
                            placeholder: 'your first name',
                            name: 'firstName',
                            isRequired: true,
                        },
                        {
                            type: 'text',
                            placeholder: 'your last name',
                            name: 'lastName',
                            isRequired: true,
                        },
                        {
                            type: 'email',
                            placeholder: 'your email address',
                            name: 'email',
                        },
                        {
                            type: 'group',
                            label: 'phone number',
                            elements: [
                                {
                                    type: 'text',
                                    placeholder: '+216',
                                    name: 'prefix',
                                },
                                {
                                    type: 'text',
                                    placeholder: '8023456789',
                                    name: 'number',
                                },
                            ]
                        },
                        {
                            type: 'textArea',
                            placeholder: 'please type your message in here...',
                            name: 'message',
                        },
                        {
                            type: 'captcha',
                        },
                        {
                            type: 'captcha_space'
                        },
                        {
                            type: 'cancel',
                            placeholder: 'cancel',
                            class: 'col-12 col-sm12 col-md-6 col-lg-6'
                        },
                        {
                            type: 'submit',
                            placeholder: 'Continue',
                            class: 'col-12 col-sm12 col-md-6 col-lg-6'
                        },
                    ]
                },
                success: {
                    content: `We have received your message successfully.
                    We will get back to you shortly`
                }
            },
            formData: null, // the actual form data from the user, not from the page
            isSubmitted: false, // temp for when we add the logic, it will be a props response from axios
            isPhoneToggled: false,
            selectedPhonePrefix: null,
            regionPhonePrefix: 'AE', // preferred phone prefix for the region
            isCatptchaSubmitted: false,
            invalidEmail: false,
            serverError: false
        }

        this.phoneNumbers = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
        this.selectPreferredPhonePrefix(this.state.regionPhonePrefix)
    }

    selectPreferredPhonePrefix = (prefix) => {
        if (phones?.length) {
            for (let phone of phones) {
                if (phone?.code === prefix) {
                    this.selectPhonePrefix(phone)
                    break;
                }
            }
        }
    } 

    pageClick = (e) => {
        if (this.phoneNumbers && this.phoneNumbers.current && !this.phoneNumbers.current.contains(e.target) && this.state.isPhoneToggled) {
            this.togglePhoneNumbers(false)
        }
    }

    renderPhoneNumbers = () => {
        if (phones?.length && this.state.isPhoneToggled)
            return (
                <div className='phone-numbers' ref={this.phoneNumbers}>
                    {
                        phones.map((phone, index) => {
                            return (
                                <div className='element' key={index} onClick={() => this.selectPhonePrefix(phone)}>
                                    <div className='flag'>
                                        <ReactCountryFlag countryCode={phone?.code} svg/>
                                    </div>
                                    <div className='country-name'>{phone?.dial_code}</div>
                                </div>
                            )
                        })
                    }
                </div>
            )
    }

    togglePhoneNumbers = (value) => {
        this.setState({
            isPhoneToggled: value
        })
    }

    selectPhonePrefix = (phone) => {
        this.setState({
            selectedPhonePrefix: {
                prefix: phone?.dial_code,
                flag: phone?.code
            },
            isPhoneToggled: false,
            formData: {
                ...this.state.formData,
                prefix: phone?.dial_code
            }
        })
    }

    handleInput = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        })
    }

    isCancelBtnDisabled = () => {
        let isDisabled = true;

        if (this.state.formData === null)
            isDisabled = true; // repetition but for clarity
        else {
            let form = this.state.formData;

            for (const type in form) {
                if (form.hasOwnProperty(type)) {
                    let value = form[type];

                    if (value !== '') {
                        isDisabled = false;
                        break;
                    }
                }
            }
        }

        return isDisabled ? ' disabled' : ''
    }

    cancel = () => {
        if (this.isCancelBtnDisabled() === ' disabled' || this.state.isUploading) return;

        this.setState({
            formData: null
        })
    }

    renderForm = () => {
        return (
            <form 
                className="form" 
                autoComplete='off'
                onSubmit={this.handleJoin}
            >
                <div className='row'>
                    {this.renderFormElements()}
                    {this.renderLoader()}
                </div>
            </form>
        )
    }

    renderFormElements = () => {
        if (!this.state.page?.form?.elements?.length) return;

        return this.state.page.form.elements.map((element, index) => {
            return this.renderSignleFormElement(element, index)
        })
    }

    renderSignleFormElement = (element) => {
        return (
            <div className={'input-container ' + (element?.class || 'col-12 col-sm12 col-md-12 col-lg-12')}>
                <label className='label'>{element?.label || ''}</label>
                {
                    this.switchFormElement(element)
                }
            </div>
        )
    }

    renderDoubleInput = (elements) => {
        if (!elements?.length) return null

        return (
            <>
                <div className='double-input'>
                    {
                        elements?.map(element => {
                            if (element?.name === 'prefix')
                                return (
                                    <>
                                        <input 
                                            className='prefix'
                                            type={element?.type}
                                            placeholder={element?.placeholder} 
                                            onClick={() => this.togglePhoneNumbers(!this.state.isPhoneToggled)}
                                            value={this.state.selectedPhonePrefix?.prefix || ''}
                                            name={element?.name}
                                            onChange={(e) => this.handleInput(e)}
                                        />
                                        <div className='selected-flag'>
                                            <ReactCountryFlag countryCode={this.state.selectedPhonePrefix?.flag} svg/>
                                        </div>
                                        {this.renderPhoneNumbers()}
                                    </>
                                )
                            return <input type={element?.type} name={element?.name} placeholder={element?.placeholder} onChange={(e) => this.handleInput(e)}/>
                        })
                    }
                </div>
            </>
        )
    }

    switchFormElement = (element, index) => {
        switch (element?.type) {
            case 'text':
            case 'email':
                return (
                    <>
                        {
                            element?.type === 'email' ?
                                this.renderRequiredError(this.state.invalidEmail, 'Your email must be valid') :
                                this.renderRequiredError(element?.isInvalid, 'This field is required')
                        }
                        <input
                            type={element?.type}
                            placeholder={element?.placeholder}
                            name={element?.name}
                            value={this.state.formData !== null ? this.state.formData[element?.name] : ''}
                            required={false && element?.isRequired}
                            onChange={(e) => this.handleInput(e)}
                            className={element?.isInvalid ? 'error-required' : ''}
                        />
                    </>
                )
            case 'textArea':
                return (
                    <textarea name={element?.name} onChange={(e) => this.handleInput(e)} placeholder={element?.placeholder} rows={8}>
                        
                    </textarea>
                )
                break;
            case 'file':
                return (
                    <>
                        {this.renderRequiredError(this.state.fileSizeLimit === element?.name, 'File max size is 10MB')} 
                        <div className='file-container'>
                            <label class="custom-file-upload">
                                <input type="file" name={element?.name} onChange={this.handleFileInput} accept="application/pdf"/>
                                {(this.state.formData !== null && this.state.formData[element?.name+'Name']) || element?.placeholder}
                            </label>
                            <i className='bx bxs-cloud-upload' />
                        </div>
                    </>
                )
            case 'group':
                return this.renderDoubleInput(element?.elements)
            case 'submit':
                return (
                    <div className='submit-container'>
                        <input type='submit' className={'submit' + (this.state.isUploading ? ' disabled' : '')} value={element?.placeholder}/>
                    </div>
                )
            case 'cancel':
                return (
                    <div className='submit-container'>
                        <div className={'cancel' + (this.isCancelBtnDisabled()) + (this.state.isUploading ? ' disabled' : '')} onClick={this.cancel}>
                            {element?.placeholder}
                        </div>
                    </div>
                )
            case 'captcha':
                return (
                    <>
                        <ReCAPTCHA 
                            sitekey="6Lded-QkAAAAALXNW2DzzaJhTSNIB7ZFyO1MPNrx"
                            onChange={this.verifyCaptcha}
                            onExpired={() => this.setState({isCatptchaSubmitted: false})}
                        />
                        {this.renderRequiredError(this.state.isCaptchaError, 'You need to check the captcha before submitting')}
                    </>
                )
            case 'captcha_space':
                return null
        }
    }

    verifyCaptcha = (token) => {
        this.setState({
            isCatptchaSubmitted: true
        })
    }

    handleJoin = (e) => {
        e.preventDefault()

        this.setState({
            serverError: false
        })

        if (this.state.isUploading) return;

        // TODO add an axios post API
        if (!this.validateEmail(this.state.formData?.email)) return this.setState({invalidEmail: true});

        if (!this.checkRequiredFields()) return;

        if (!this.state.isCatptchaSubmitted) {
            this.setState({
                isCaptchaError: true
            })

            return;
        }
        
        this.setState({
            isCaptchaError: false
        })

        // we need to send formData before toggle because we init the values
        this.setState({
            isUploading: true
        })

        axios.post(`https://versemail.azurewebsites.net/mail/contact`, {formData: this.state.formData}).then(response => {
            if (response?.data) {
                this.toggleSubmitted(true);
            }
        }).catch(error => {
            this.setState({
                serverError: true,
                isUploading: false
            })
        });
    }

    checkRequiredFields = () => {
        if (!this.state.page?.form?.elements?.length) return false;

        let passed = true;

        let fields = this.state.page?.form?.elements?.map((element) => {
            element.isInvalid = false; // to initialize old values
            if (element?.isRequired && (!this.state.formData[element?.name] || this.state.formData[element?.name] === '')) {
                element.isInvalid = true;
                passed = false;
            }

            return element
        })

        this.setState({
            page: {
                ...this.state.page,
                form: {
                    ...this.state.page?.form,
                    elements: fields
                }
            }
        })

        return passed
    }

    toggleSubmitted = (value) => {
        this.setState({
            isSubmitted: value,
            formData: null, // init the component
            isCatptchaSubmitted: false,
            isUploading: false,
            serverError: false
        })
    }

    validateEmail = (email) => {
        if (!email || email === '') {
            this.setState({
                invalidEmail: true
            });

            return;
        }

        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    handleFileInput = (e) => {
        if (!e.target?.files?.length) return;

        let file = e.target.files[0];
        if (!file) return;

        if (file?.size > 10000000) { // approx 10MB
            return this.setState({
                fileSizeLimit: e.target.name
            })
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [e.target.name]: reader.result,
                    [e.target.name + 'Name']: file?.name
                }
            })
        };
    };

    switchRender = () => {
        if (this.state.isSubmitted)
            return <JoinSuccess
                message={this.state.page?.success?.content}
                toggleSubmitted={this.toggleSubmitted}
            />
        else 
            return this.renderForm()
    }

    renderRequiredError = (requiredCondition, message) => {
        if (requiredCondition)
            return (
                <div className='error'>
                    {message}
                </div>
            )
    }

    renderLoader = () => {
        if (this.state.serverError) {
            return (
                <p className='uploading error'>Oups! There was an error. Please retry again or refresh the page</p>
            )
        }
    }

    render() {
        return (
            <div className='contact-container'>
                <Titles
                    title={this.state.page?.title?.text}
                    subtitle={this.state.page?.subtitle?.text}
                />

                <section className='contact-us card-element'>
                    {this.switchRender()}
                </section>
            </div>
        )
    }
}
