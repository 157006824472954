import React, { Component } from 'react'
import Footer from '../components/Common/Footer'
import Header from '../components/Common/Header'
import SEO from '../components/Common/SEO'
import Contact from '../components/Contact'
import Layout from '../layouts/Layout'

export default class contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoaded: false
        }
    }

    componentDidMount = () => {
        this.setState({
            isLoaded: true
        })
    }

    render() {
        return (
            <div id="scrollable">
                <Header isTransparent={false} isLoaded={this.state.isLoaded} />
                <SEO
                    title='Contact us'
                />
                <div className='snap'>
                    <Contact />
                </div>
                <Footer />
            </div>
        )
    }
}
